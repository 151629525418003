
  import { Component, Vue } from 'vue-property-decorator'
  import { QuestionType } from '@/interface/BaseInterface'

  @Component
  export default class QuestionFilter extends Vue {
    filter: any = {
      search: '',
      type: ''
    }

    questionType = QuestionType

    timeCount: any = ''

    handleSearchChange() {
      clearTimeout(this.timeCount)
      this.timeCount = setTimeout(() => {
        this.$emit('filter', this.filter)
      }, 300)
    }

    handleSelectChange() {
      this.$emit('filter', this.filter)
    }
  }
