
  import { Vue, Component } from 'vue-property-decorator'
  import { QuestionInterface, MetaInterface, QuestionType } from '@/interface/BaseInterface'
  import QuestionApi from '../api/questionApi'
  import getApi from '@/api'
  import { filter, assign } from 'lodash'
  import QuestionFilter from '../components/filter/QuestionFilter.vue'

  const questionApi: QuestionApi = getApi('question')

  @Component({
    components: { QuestionFilter }
  })
  export default class QuestionBank extends Vue {
    data: QuestionInterface[] = []
    meta: MetaInterface = {
      page: 1,
      perPage: 10,
      total: 1
    }

    filter = {
      searchText: ''
    }

    isLoading = false

    handleFilter(filter: any) {
      this.filter.searchText = filter.search
      this.meta = {
        page: 1,
        perPage: 10,
        total: 100
      }
      this.init()
    }

    handleEdit(data: QuestionInterface) {
      this.$router.push({ name: 'questionBankEdit', params: { id: this.courseId, questionId: data._id } })
    }

    async handleAdd() {
      try {
        const result = await questionApi.create({
          name: 'New question',
          type: QuestionType.MUTILCHOICE
        })

        this.$router.push({ name: 'questionBankEdit', params: { id: this.courseId, questionId: result._id } })
      } catch (error) {
        console.log(error)
      }
      // this.$router.push({ name: 'questionBankCreate' })
    }

    handleSizeChange(size: number) {
      this.meta.perPage = size
      this.meta.page = 1
      this.init()
    }

    handlePageChange(page: number) {
      this.meta.page = page
      this.init()
    }

    async handleRemove(data: QuestionInterface) {
      try {
        await questionApi.delete(data._id)
        this.data = filter(this.data, value => {
          return value._id !== data._id
        })
        this.$message.success(this.$t('removeSuccess') as string)
      } catch (error) {
        this.$message.error(this.$t('removeErr') as string)
      }
    }

    async init() {
      try {
        this.isLoading = true
        const result = await questionApi.all(assign(this.meta, this.filter))
        this.data = result.items
        this.meta.total = result.meta.total
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }

    created() {
      this.init()
    }
  }
